var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"auth-wrapper auth-v1 px-2"},[_c('ButtonLogoutVue'),_c('div',{staticClass:"auth-inner py-2"},[_c('b-card',{staticClass:"mb-0 pb-1"},[_c('b-link',{staticClass:"brand-logo"},[_c('b-img',{attrs:{"src":_vm.logoUic}})],1),_c('b-card-title',{staticClass:"mb-1"},[_vm._v(" Kirim dan Validasi OTP. ")]),_c('b-card-text',{staticClass:"mb-2"},[_vm._v(" Pastikan nomer Handphone yang anda input valid. ")]),_c('validation-observer',{ref:"loginForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-form',{staticClass:"auth-login-form mt-2",on:{"submit":function($event){$event.preventDefault();}}},[_c('b-form-group',{attrs:{"label-for":"pilih-negara","label":"Pilih Negara"}},[_c('validation-provider',{attrs:{"name":"Pilih Negara","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vSelect',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"label":"name","options":_vm.listKodeNegara,"reduce":function (negara) { return negara.dialCode; },"disabled":_vm.isInputKodeOtp},model:{value:(_vm.kodeNegara),callback:function ($$v) {_vm.kodeNegara=$$v},expression:"kodeNegara"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',[_c('div',{staticClass:"d-flex justify-content-between"},[_c('label',{attrs:{"for":"nomor-handphone"}},[_vm._v("Nomor Handphone")]),(_vm.isInputKodeOtp && _vm.counterdown === 0)?_c('a',{attrs:{"href":"javascript:void(0)"},on:{"click":function($event){_vm.isInputKodeOtp = false, _vm.kodeOtp = ''}}},[_c('small',[_vm._v("Ganti Nomor Handphone")])]):_vm._e()]),_c('validation-provider',{attrs:{"name":"Nomor Handphone","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid':null},[_c('b-input-group-prepend',{class:_vm.isInputKodeOtp ? 'disabled-input-group-text' : null,attrs:{"is-text":"","disabled":_vm.isInputKodeOtp}},[_vm._v(" "+_vm._s(_vm.kodeNegara ? ("+" + _vm.kodeNegara) : null)+" ")]),_c('b-form-input',{attrs:{"id":"nomor-handphone","type":"number","name":"nomor-handphone","state":errors.length > 0 ? false:null,"placeholder":"82xxxxxx","disabled":_vm.isInputKodeOtp,"autofocus":""},model:{value:(_vm.nomorHandphone),callback:function ($$v) {_vm.nomorHandphone=$$v},expression:"nomorHandphone"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),(_vm.isInputKodeOtp)?_c('b-form-group',{attrs:{"label-for":"kode-otp","label":"Kode OTP"}},[_c('validation-provider',{attrs:{"name":"Kode OTP","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"kode-otp","name":"kode-otp","state":errors.length > 0 ? false:null,"placeholder":"1234"},model:{value:(_vm.kodeOtp),callback:function ($$v) {_vm.kodeOtp=$$v},expression:"kodeOtp"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1):_vm._e(),(_vm.isInputKodeOtp)?_c('b-button',{attrs:{"variant":"primary","type":"submit","block":"","disabled":invalid},on:{"click":_vm.sumbitOtp}},[_vm._v(" Submit OTP ")]):_c('b-button',{attrs:{"variant":"primary","type":"submit","block":"","disabled":invalid},on:{"click":_vm.kirimOtp}},[_vm._v(" Kirim OTP ")])],1)]}}])}),(_vm.isInputKodeOtp)?_c('b-card-text',{staticClass:"text-center mt-2"},[(_vm.counterdown > 0)?_c('p',{staticClass:"mt-1"},[_vm._v(" "+_vm._s(_vm.counterdown)+" detik tersisa untuk kirim OTP kembali ")]):_c('a',{attrs:{"href":"javascript:void(0)"},on:{"click":_vm.kirimOtp}},[_vm._v("Kirim OTP Lagi")])]):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div class="auth-wrapper auth-v1 px-2">
    <ButtonLogoutVue />
    <div class="auth-inner py-2">

      <!-- Login v1 -->
      <b-card class="mb-0 pb-1">
        <b-link class="brand-logo">
          <b-img :src="logoUic" />
        </b-link>

        <b-card-title class="mb-1">
          Kirim dan Validasi OTP.
        </b-card-title>
        <b-card-text class="mb-2">
          Pastikan nomer Handphone yang anda input valid.
        </b-card-text>

        <!-- form -->
        <validation-observer
          ref="loginForm"
          #default="{invalid}"
        >
          <b-form
            class="auth-login-form mt-2"
            @submit.prevent
          >

            <b-form-group
              label-for="pilih-negara"
              label="Pilih Negara"
            >
              <validation-provider
                #default="{ errors }"
                name="Pilih Negara"
                rules="required"
              >
                <vSelect
                  v-model="kodeNegara"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="name"
                  :options="listKodeNegara"
                  :reduce="negara => negara.dialCode"
                  :disabled="isInputKodeOtp"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group>
              <div class="d-flex justify-content-between">
                <label for="nomor-handphone">Nomor Handphone</label>
                <a
                  v-if="isInputKodeOtp && counterdown === 0"
                  href="javascript:void(0)"
                  @click="isInputKodeOtp = false, kodeOtp = ''"
                >
                  <small>Ganti Nomor Handphone</small>
                </a>
              </div>
              <validation-provider
                #default="{ errors }"
                name="Nomor Handphone"
                rules="required"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid':null"
                >
                  <b-input-group-prepend
                    is-text
                    :disabled="isInputKodeOtp"
                    :class="isInputKodeOtp ? 'disabled-input-group-text' : null"
                  >
                    {{ kodeNegara ? `+${kodeNegara}` : null }}
                  </b-input-group-prepend>
                  <b-form-input
                    id="nomor-handphone"
                    v-model="nomorHandphone"
                    type="number"
                    name="nomor-handphone"
                    :state="errors.length > 0 ? false:null"
                    placeholder="82xxxxxx"
                    :disabled="isInputKodeOtp"
                    autofocus
                  />
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group
              v-if="isInputKodeOtp"
              label-for="kode-otp"
              label="Kode OTP"
            >
              <validation-provider
                #default="{ errors }"
                name="Kode OTP"
                rules="required"
              >
                <b-form-input
                  id="kode-otp"
                  v-model="kodeOtp"
                  name="kode-otp"
                  :state="errors.length > 0 ? false:null"
                  placeholder="1234"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- submit button -->
            <b-button
              v-if="isInputKodeOtp"
              variant="primary"
              type="submit"
              block
              :disabled="invalid"
              @click="sumbitOtp"
            >
              Submit OTP
            </b-button>
            <b-button
              v-else
              variant="primary"
              type="submit"
              block
              :disabled="invalid"
              @click="kirimOtp"
            >
              Kirim OTP
            </b-button>
          </b-form>
        </validation-observer>
        <b-card-text
          v-if="isInputKodeOtp"
          class="text-center mt-2"
        >
          <p
            v-if="counterdown > 0"
            class="mt-1"
          >
            {{ counterdown }} detik tersisa untuk kirim OTP kembali
          </p>
          <a
            v-else
            href="javascript:void(0)"
            @click="kirimOtp"
          >Kirim OTP Lagi</a>
        </b-card-text>

      </b-card>
    </div>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BButton,
  BForm,
  BFormInput,
  BFormGroup,
  BCard,
  BLink,
  BCardTitle,
  BCardText,
  BInputGroup,
  BImg,
  BInputGroupPrepend,
} from 'bootstrap-vue'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import vSelect from 'vue-select'
import API from '@/api'
import getDataFromStorage, { setUserStorage } from '@/helpers/getDataFromStorage'
import { initialAbility } from '@/libs/acl/config'
import logoUic from '@/assets/images/logo-uic.png'
import allCountries from '@/helpers/allCountries'
import ButtonLogoutVue from '@/components/ButtonLogout/ButtonLogout.vue'

export default {
  components: {
    // BSV
    BButton,
    BForm,
    BFormInput,
    BFormGroup,
    BCard,
    BCardTitle,
    BLink,
    BCardText,
    BInputGroup,
    ValidationProvider,
    ValidationObserver,
    BImg,
    vSelect,
    BInputGroupPrepend,
    ButtonLogoutVue,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      status: '',
      kodeNegara: '62',
      listKodeNegara: allCountries,
      // nomorHandphone: '895334199749',
      nomorHandphone: '',
      kodeOtp: '',
      isInputKodeOtp: false,
      counterdown: 30,
      logoUic,
      // validation rules
      required,
      email,
    }
  },
  computed: {
  },
  methods: {
    countDown() {
      this.counterdown = 60
      this.countingDown = setInterval(() => {
        if (this.counterdown > 0) {
          this.counterdown -= 1
          if (!this.isInputKodeOtp) {
            clearTimeout(this.countingDown)
          }
        } else {
          clearTimeout(this.countingDown)
        }
      }, 1000)
    },
    async sumbitOtp() {
      try {
        this.$store.commit('SET_IS_LOADING_FOR_SUBMIT', true)
        const { data } = await API.evote.validateOtp({ otp: this.kodeOtp })

        setUserStorage({
          ...getDataFromStorage(),
          tokenVote: data.token_vote,
          isOtp: true,
        })
        this.$ability.update(
          initialAbility[0],
        )
        this.$router.replace('/vote')
        this.handleMsgSuccess('Berhasil check otp')
      } catch (error) {
        console.log(error)
        this.handleMsgErrors(error, 'Kode OTP Salah, silahkan coba lagi!')
      } finally {
        this.$store.commit('SET_IS_LOADING_FOR_SUBMIT', false)
      }
    },
    async kirimOtp() {
      try {
        this.$store.commit('SET_IS_LOADING_FOR_SUBMIT', true)

        const { data } = await API.evote.insertPhoneNumber({
          kode_negara: this.kodeNegara ? `+${this.kodeNegara}` : null,
          no_telp: this.nomorHandphone,
        })

        this.isInputKodeOtp = true
        this.handleMsgSuccess(data.message)
        this.countDown()
      } catch (error) {
        console.log(error)
        this.handleMsgErrors(error, 'Nomor Handphone salah, silahkan coba lagi!')
      } finally {
        this.$store.commit('SET_IS_LOADING_FOR_SUBMIT', false)
      }
    },
  },
}
</script>

<style>
  .logout {
    background: #E5E5E5;
  }
</style>

<style scoped>
 .disabled-input-group-text div {
    background: #efefef;
 }
</style>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
@import '@core/scss/vue/libs/vue-select.scss';
</style>
